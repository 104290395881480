import React, { useEffect, useState, FormEvent , useRef} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { login } from "../services/apiService";
import { useRecoilState } from "recoil";
import {
  defaultUseSomeoneDotBotrunAtUrl,
  userAuthState,
} from "../recoil/atoms";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
//import ReCAPTCHA from "react-google-recaptcha";

/*
import React, { useEffect, useState, FormEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { login } from "../services/apiService";
import { useRecoilState } from "recoil";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from "uuid";
import {
  defaultUseSomeoneDotBotrunAtUrl,
  userAuthState,
} from "../recoil/atoms";



interface LoginProps {
  onLogin: () => void;
}
*/

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    maxWidth: "400px",
  },
  field: {
    margin: "10px 0",
  },
  button: {
    margin: "20px 0",
  },
  feature: {
    margin: "20px 0",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
});


function usePreviousPageInfo() {
  const [referrer, setReferrer] = useState('');

  useEffect(() => {
    setReferrer(document.referrer);
  }, []);

  const showLoginButton = true || referrer && (referrer.startsWith("http://0.0.0.0:8000") || referrer.startsWith("https://163.28.84.216") || referrer.startsWith("https://pedia.cloud.edu.tw"));

  return { showLoginButton, referrer };
}


const generateGuestUsername = () => {
  // 取得短的 uuid（只取前 8 個字元）
  const shortUUID = uuidv4().slice(0, 8);

  // 取得日期戳記（格式：yyyymmdd）
  const date = new Date();
  const timestamp = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, "0")}${String(date.getDate()).padStart(2, "0")}`;

  // 組合 username
  return `guest_${timestamp}_${shortUUID}`;
};


const LoginGuest: React.FC = () => {
  const [, setUser] = useRecoilState(userAuthState);
  const [default_botrun] = useRecoilState(defaultUseSomeoneDotBotrunAtUrl);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const classes = useStyles();
  const { t } = useTranslation();
  //const captchaRef = useRef<ReCAPTCHA>(null);
  //const [token, setToken] = useState<string | null>(null);


  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    
    // 檢查是否已經獲得 reCAPTCHA token
    //if (!token) {
      // 如果 token 為 null，提醒使用者完成 reCAPTCHA 驗證
    //  alert("請完成 reCAPTCHA 驗證！");
    //  return; // 阻止表單提交
    //}
  
    try {
      var guestusername=generateGuestUsername();
      if (guestusername !== "") {
        const word = process.env.REACT_APP_HD_WORD!;
        const data = await login(guestusername, word, default_botrun);
  
        if (data.is_success) {
          setUser({
            token: data.access_token,
            type: data.token_type,
            username: username
          });
        } else {
          setError("登入失敗 (Login Error...)");
        }
      } else {
        setError("登入失敗，請填寫 Email 喔!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  //const onReCAPTCHASuccess = (token: string | null) => {
  //  setToken(token);
  //};

  const { showLoginButton, referrer } = usePreviousPageInfo();
  if (!showLoginButton) {
    return <div>錯誤頁面，請點選回首頁：<a href="https://pedia.cloud.edu.tw/">回首頁</a><p>網址：{referrer}</p></div>;
  }

  // 正常顯示 Login 組件的其餘部分
  return (
    <div className={classes.container}>
      <h2>🦉貓頭鷹博士</h2>
      <div className={classes.feature}>
        <h3></h3>
        <ul>
          <li>
            <strong>🦉 </strong> 我是學識淵博的貓頭鷹博士，擅長解答詞語的解釋還有推薦成語，快來跟我一起快樂學習吧！

          </li>
         {" "}
        </ul>
      </div>

      <form onSubmit={handleSubmit} className={classes.form}>
        
        

        {error && <div>{error}</div>}
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
        >
          {t("開始")}
        </Button>
      </form>

      <p>...{referrer}</p>
    </div>

    
  );
};

export default LoginGuest;