import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined"
import "./FileCopyButton.css"
import Tooltip from "@mui/material/Tooltip"
import Button from "@mui/material/Button"
import { useTranslation } from "react-i18next"

// Copy to Clipboard Button Component
export const FileCopyButton = ({
  content,
  isForCodeCopy,
}: {
  content: string
  isForCodeCopy: boolean
}) => {
  function copyToClipboard(text: string) {
    text = text + "\n\n[Boosted by Botrun.ai]"
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // console.log("[FileCopyButton] Code copied to clipboard!");
      })
      .catch(err => {
        console.error("[FileCopyButton] Failed to copy: ", err)
      })
  }

  // 根據 isPressed 狀態切換按鈕類別
  // const buttonClass = `button-base ${isPressed ? "button-pressed" : "button-not-pressed"}`;
  const buttonClass = isForCodeCopy ? "button-code-copy-base" : "button-base"
  const { t } = useTranslation()

  if (isForCodeCopy)
    return (
      <Tooltip title={t("Copy")} arrow>
        <Button
          onClick={() => copyToClipboard(content)}
          variant="outlined"
          startIcon={<FileCopyOutlinedIcon sx={{ color: "white", fontSize: "15px" }} />}
          sx={{ color: "white", borderColor: "white", fontSize: "10px", padding: "3px 15px" }}
        >
          {t("Copy")}
        </Button>
      </Tooltip>
    )
  return (
    <Tooltip title={t("Copy")} arrow>
      <button onClick={() => copyToClipboard(content)} className={buttonClass}>
        <FileCopyOutlinedIcon fontSize="medium" />
      </button>
    </Tooltip>
  )
}
