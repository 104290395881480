import React, { useEffect, useState } from "react"
import { beginClientGet } from "./BeginClientGet"
import { beginClientPost } from "./BeginClientPost"
import { beginCode } from "./BeginCode"
import "./ChatMessage.css"
import { useTranslation } from "react-i18next"
import { onSendMessageAtom } from "../recoil/atoms"
import { useRecoilValue } from "recoil"
import { FileCopyButton } from "./Button/FileCopyButton"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import remarkBreaks from "remark-breaks"
import SyntaxHighlighter from "react-syntax-highlighter"
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs"

interface ChatMessageProps {
  message: string
  isUserMessage: boolean
}

interface Part {
  type: "text" | "html" | "code" | "parent_post_message" | "button"
  content: string
  language?: string
  payload?: string
}

function extractCodeDetails(input: string): { language: string; code: string } {
  const trimmedInput = input.slice(3, -3)
  const firstNewLineIndex = trimmedInput.indexOf("\n")
  const language = trimmedInput.slice(0, firstNewLineIndex).trim()
  const code = trimmedInput.slice(firstNewLineIndex).trim()

  return { language, code }
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message, isUserMessage }) => {
  const { t } = useTranslation()
  const [, setClientPostResponse] = useState<string>("")
  const [clientGetResponse, setClientGetResponse] = useState<string>("")
  const { codeContent } = isUserMessage ? { codeContent: "" } : beginCode(message)

  const [formattedMessageParts, setFormattedMessageParts] = useState<Part[]>([])
  const [actionButtons, setActionButtons] = useState<Part[]>([])

  const codeDisplayType = process.env.REACT_APP_CODE_DISPLAY_TYPE || "show"
  const [isCodeVisible, setCodeVisible] = useState(codeDisplayType === "toggle" ? false : true)

  const onSendMessage = useRecoilValue(onSendMessageAtom)

  const toggleCodeVisibility = () => {
    setCodeVisible(!isCodeVisible)
  }

  useEffect(() => {
    if (message.includes("@begin client_get") && !isUserMessage) {
      const fetchDataGet = async () => {
        const str_response = await beginClientGet(message)
        if (str_response) {
          setClientGetResponse(str_response)
        }
      }
      fetchDataGet()
    }

    if (message.includes("@begin client_post") && !isUserMessage) {
      const fetchDataPost = async () => {
        const str_response = await beginClientPost(message)
        if (str_response) {
          setClientPostResponse(str_response)
        }
      }
      fetchDataPost()
    }

    if (!isUserMessage) {
      const combinedRegex =
        /@begin\s*(link|img|code|parent_post_message|button)\("([^"]+)"\)\s*([\s\S]*?)\s*@end/g

      let parts: Part[] = []
      let buttons: Part[] = []
      let lastIndex = 0

      const parseCustomTags = (regex: RegExp, text: string) => {
        text.replace(regex, (match, type, url_or_target, textContent, offset) => {
          if (offset > lastIndex) {
            const lastCharacter = text.substring(lastIndex, offset).trim()
            if (lastCharacter !== "\n")
              parts.push({
                type: "text",
                content: lastCharacter,
              })
          }

          if (type === "link") {
            parts.push({
              type: "html",
              content: ` <a href="${url_or_target}" target="_blank" rel="noopener noreferrer"
              style="color: #57a8ff">${textContent}</a> `,
            })
          } else if (type === "img") {
            parts.push({
              type: "html",
              content: `<br/><img src="${url_or_target}" alt="" /><br/>`,
            })
          } else if (type === "code") {
            const { language, code } = extractCodeDetails(textContent)
            parts.push({ type: "code", content: code, language: language })
          } else if (type === "parent_post_message") {
            window.parent.postMessage(textContent, url_or_target)
          } else if (type === "button") {
            buttons.push({
              type: "button",
              content: url_or_target,
              payload: textContent.trim() || url_or_target,
            })
          }
          lastIndex = offset + match.length
          return match
        })

        if (lastIndex < text.length) {
          parts.push({ type: "text", content: text.substring(lastIndex).replace(/^```|```$/g, "") })
        }
      }

      parseCustomTags(combinedRegex, message)
      setFormattedMessageParts(parts)
      setActionButtons(buttons)
    }
  }, [message, isUserMessage])

  const handleButtonClick = (payload: string) => {
    onSendMessage(payload, true)
  }

  return (
    <div className={`chat-item ${isUserMessage ? "self" : ""}`}>
      <div className="chat-block">
        <div>
          {isUserMessage ? (
            <pre
              style={{
                margin: 0,
                lineHeight: "1.6rem",
                whiteSpace: "pre-wrap",
              }}
            >
              <code>{message}</code>
            </pre>
          ) : (
            formattedMessageParts.map((part, index) => {
              if (part.type === "html") {
                return <div key={index} dangerouslySetInnerHTML={{ __html: part.content }} />
              } else if (part.type === "code") {
                return (
                  <div key={index}>
                    {codeContent && codeDisplayType === "toggle" && (
                      <>
                        <button onClick={toggleCodeVisibility}>
                          {isCodeVisible ? t("Hide Source Code") : t("Show Source Code")}
                        </button>
                        <div className={`code-style-top ${isCodeVisible ? "" : "hidden"}`}>
                          {part.language}
                          <FileCopyButton content={part.content} isForCodeCopy={true} />
                        </div>
                        <div className={`code-style-body ${isCodeVisible ? "" : "hidden"}`}>
                          <SyntaxHighlighter language={part.language} style={darcula}>
                            {part.content}
                          </SyntaxHighlighter>
                        </div>
                        <div className={`code-style-bottom ${isCodeVisible ? "" : "hidden"}`}>
                          Boosted by Botrun.ai
                        </div>
                      </>
                    )}
                  </div>
                )
              } else {
                return (
                  <Markdown className="markdown-wrapper" remarkPlugins={[remarkGfm, remarkBreaks]}>
                    {part.content}
                  </Markdown>
                )
              }
            })
          )}
        </div>
        {clientGetResponse && <pre style={{ margin: 0, padding: 0 }}>{clientGetResponse}</pre>}
      </div>
      {actionButtons.length ? (
        <div className="suggestion-block">
          <h3>可以進行的動作</h3>
          <div className="btn-fn-group">
            {actionButtons.map((button, index) => (
              <div
                className="btn-fn"
                key={`action-button-${index}`}
                onClick={() => button.payload && handleButtonClick(button.payload)}
              >
                {button.content}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {!isUserMessage ? <FileCopyButton content={message} isForCodeCopy={false} /> : null}
    </div>
  )
}

export default ChatMessage
