import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { InitOptions } from "i18next";

const i18nConfig: InitOptions = {
  lng: "en",
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: "/locales/{{lng}}.json",
  },
  supportedLngs: ["en", "zh-Hant"],
};

i18n
  .use(Backend) // enables loading translations from a server
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .init(i18nConfig);

export default i18n;
