// ./services/apiServices.ts
import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";

export const getApiUrl = (url: string, username: string = "") => {
  // 這裡可以使用 usernameForApiConcate
  // 實現 useApiUrl 的邏輯
  const useDockerInDocker = process.env.REACT_APP_USE_DOCKER_IN_DOCKER === 'True';
  const urlPatternToReplace = "/api/botrun";
  let urlStringToReplace = "/api/botrun";
  if (useDockerInDocker && username !== "") {
    let sanitizedUsername = username.replace(/[@.]/g, '_');
    urlStringToReplace += "/" + sanitizedUsername;
  }
  // 將 url 中的 urlPatternToReplace 替換為 urlStringToReplace
  const newUrl = url.replace(urlPatternToReplace, urlStringToReplace);

  return newUrl;
};

export const uploadFiles = async (data: FormData, username:string = "") => {
  try {
    const uploadApiUrl = getApiUrl(`${API_BASE_URL}/api/botrun/upload_files`, username);
    const response = await axios.post(
      uploadApiUrl,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export async function login(
  username: string,
  password: string,
  default_botrun: string = "index"
) {
  const params = new URLSearchParams();
  params.append("username", username);
  params.append("password", password);

  const response = await axios.post(
    `${API_BASE_URL}/api/botrun/login_for_access_token?default_botrun=${default_botrun}`,
    params,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data;
}

export async function tokenVerify(botrunToken: string, defaultBotrun: string = "index", username: string = "") {
  const params = new URLSearchParams();
  params.append("access_token", botrunToken);
  params.append("default_botrun", defaultBotrun);

  const verifyApiUrl = getApiUrl(`${API_BASE_URL}/api/botrun/token_verify`, username);
  try {
    const response = await axios.post(
      verifyApiUrl,
      params,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;  
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // 現在 error 被認為是 AxiosError 類型
      if (error.response && error.response.status === 404) {
        console.error("404錯誤: 請求的資源找不到");
      } else {
        console.error("發生錯誤: ", error.message);
      }
    } else {
      // 非 Axios 錯誤
      console.error("非 Axios 錯誤", error);
    }
    return {"is_success": false}
  }
}

export async function checkUserDocker(username: string = "", defaultBotrun: string = "index") {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/botrun/check-docker?username=${username}&default_botrun=${defaultBotrun}`,
      {
        headers: {
          "Accept": "application/json", // 通常在 GET 請求中設定 Accept header
        },
      }
    );
    return response.data;  
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // 現在 error 被認為是 AxiosError 類型
      if (error.response && error.response.status === 404) {
        console.error("404錯誤: 請求的資源找不到");
      } else {
        console.error("發生錯誤: ", error.message);
      }
    } else {
      // 非 Axios 錯誤
      console.error("非 Axios 錯誤", error);
    }
    return {"is_success": false};
  }
}

export const verifyRecaptcha = async (token: string) => {
  const verifyApiUrl = `${API_BASE_URL}/api/botrun/verify_captcha`;
  try {
    const response = await axios.post(
      verifyApiUrl,
      JSON.stringify({ token }), 
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
    const data = response.data;
    if (data.success && data.score > 0.5) {
      return { isSuccess: true, score: data.score };
    } else {
      return { isSuccess: false, message: "Captcha verification failed" };
    }
  } catch (error: unknown) {  // 指明 error 為 unknown 類型
    console.error('Error during captcha verification', error);
    // 進行類型斷言
    if (error instanceof Error) {
      return { isSuccess: false, message: error.message };
    } else {
      return { isSuccess: false, message: "An error occurred during captcha verification" };
    }
  }
};
